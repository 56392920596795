import * as dompack from 'dompack';
import * as dialog from 'dompack/components/dialog';
import * as dialogapi from 'dompack/api/dialog';
import {Modal} from 'bootstrap/dist/js/bootstrap.js';
import * as whintegration from '@mod-system/js/wh/integration';
import PromidataProduct from '@mod-forshops/shopextensions/promidata/frontend/promidataproduct'

export default class TotziensPromidataProduct extends PromidataProduct
{
  constructor(node, product, initialhashparams)
  {
    super(node, product, initialhashparams);

    for(let quoterequestbutton of this.node.querySelectorAll(".promidata--js-quoterequest"))
    quoterequestbutton.addEventListener("click", evt => this._checkMinQuantity(evt));

  }

  _checkMinQuantity()
  {
    let selectedoptions = this._getCurrentOptions();
    let selectedoptions_str = JSON.stringify(selectedoptions);
    let rawprices = this.productinfo.pricematrix.filter(row => JSON.stringify(row.options) == selectedoptions_str);

    let minquantity = rawprices[0]?.prices.map(_ => _.quantity)[0];
    let minimumprice = 100.00;
    let finalprice = this._getFinalPrice()
    let getquantity = this._getTotalQuantity();

    if(getquantity < minquantity)
    {  
      let message =
      <div>
        <p>Het minimum aantal te bestellen is {minquantity}</p>
      </div>;

      dialogapi.runMessageBox(message, [ { title:"OK" } ], { allowcancel:true });
    } else if (finalprice < minimumprice) {
      let message =
      <div>
        <p>De minimale orderwaarde voor het aanvragen van een offerte is &euro; {minimumprice}.</p>
      </div>;

      dialogapi.runMessageBox(message, [ { title:"OK" } ], { allowcancel:true });

    } else {
      const offerteModal = new Modal(document.getElementById('offerteModal'));
      offerteModal.show();
    }
  }

  _getTotalQuantity()
  {
    let res = this._describeCurrentConfiguration();
    return res.totalquantity;
  }
  _getFinalPrice()
  {
    let res = this._describeCurrentConfiguration();
    return res.finalprice;
  }
}
